<template>
  <div>
    <div class="BgWhiteBS p-3 mt-2">
      <div class="vx-row filterBy w-full">Filter By</div>
      <div class="vx-row w-full">
        <div class="vx-row titles mt-6">
          <div class="vx-row items-center mr-4">
            Company: <vs-input class="ml-2" />
          </div>
          <div class="vx-row items-center">
            Order No: <vs-input class="ml-2" style="max-width: 60px" />
          </div>
          <vs-button class="ml-2" type="filled" icon="filter_alt"></vs-button>
        </div>
        <div class="grayLine ml-2 mr-2 sm:block hidden"></div>
        <div class="vx-row titles">
          <div class="vx-row items-center mr-4 mt-6">Purchase Date</div>
          <div class="vx-row items-center mr-4">
            <div class="vx-col text-center">
              <div>
                Start <vs-icon icon="date_range" color="dark"> </vs-icon>
              </div>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="fromDate"
                placeholder="dd.mm.yyyy"
                @on-change="onFromChange"
              />
            </div>

            <div class="mt-6">-</div>

            <div class="vx-col text-center">
              <div>End <vs-icon icon="date_range" color="dark"> </vs-icon></div>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="toDate"
                placeholder="dd.mm.yyyy"
                @on-change="onToChange"
              />
            </div>
          </div>
          <vs-button class="mt-6" type="filled" icon="filter_alt"></vs-button>
        </div>
        <div class="grayLine ml-2 mr-2 sm:block hidden"></div>
        <div class="vx-row titles mt-8">
          <div class="vx-col p-0 m mx-auto">
            <vs-checkbox>New</vs-checkbox>
          </div>
          <div class="vx-col p-0 m mx-auto">
            <vs-checkbox>Processing</vs-checkbox>
          </div>
          <div class="vx-col p-0 m mx-auto">
            <vs-checkbox>Ready</vs-checkbox>
          </div>
          <div class="vx-col p-0 m mx-auto">
            <vs-checkbox>Cancelled</vs-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="BgWhiteBS vx-row p-1 m-0 mt-4 mb-4 orderBar pt-3 pb-3">
      <div class="vx-col md:w-1/12 text-center">No</div>
      <div class="vx-col md:w-1/12 text-center">Created</div>
      <div class="vx-col md:w-2/12 text-center">Company</div>

      <div class="vx-col md:w-2/12 text-center">Total Resin / Credit</div>
      <div class="vx-col md:w-1/12 ml-auto text-center">Status</div>
    </div>

    <div class="BgWhiteBS" v-for="order in FAKEDATA" :key="order.id">
      <order-row :order="order" @OpenOrderDetail="OpenOrderDetail" />
    </div>

    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="HandleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import orderRow from "./OrderListRow.vue";

export default {
  data() {
    return {
      totalPage: 1,
      currentPage: 1,
      FAKEDATA: [
        {
          id: 153,
          date: "01.01.2020",
          companyName: "Company 1",
          credit: 4.5,
          status: "R",
          status_display: "Ready",
        },
        {
          id: 152,
          date: "01.01.2020",
          companyName: "Company 2",
          credit: 10,
          status: "P",
          status_display: "Processing",
        },
        {
          id: 151,
          date: "01.01.2020",
          companyName: "Company 3",
          credit: 3.7,
          status: "N",
          status_display: "New",
        },
        {
          id: 150,
          date: "01.01.2020",
          companyName: "Company 4",
          credit: 2.5,
          status: "C",
          status_display: "Cancelled",
        },
        {
          id: 149,
          date: "01.01.2020",
          companyName: "Company 4",
          credit: 10,
          status: "C",
          status_display: "Cancelled",
        },
        {
          id: 148,
          date: "01.01.2020",
          companyName: "Company 1",
          credit: 6,
          status: "R",
          status_display: "Ready",
        },
        {
          id: 147,
          date: "01.01.2020",
          companyName: "Company 1",
          credit: 2,
          status: "R",
          status_display: "Ready",
        },
      ],
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
    };
  },
  methods: {
    HandleChangePage(page) {
      this.currentPage = page;
      //GET DATA FROM API
    },
    OpenOrderDetail(order) {
      this.$emit("OpenOrderDetail", order);
    },
    //Date Functions
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  components: {
    orderRow,
    flatPickr,
  },
};
</script>

<style>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.flatpickr-input {
  max-width: 100px;
}
</style>

<style scoped>
.vx-row {
  padding: 0px;
  margin: 0px;
}
.grayLine {
  border: 1px solid #dedede;
}
.filterBy {
  color: #434343;
  font-weight: 900;
}
.titles {
  font-weight: 500;
  color: #434343;
}
.orderBar {
  font-weight: 600;
  color: #626262;
}
</style>
