<template>
  <div>
    <div class="flex content-between flex-wrap BgWhiteBS h-full p-4">
      <div class="pl-4 mt-1 w-full">
        <div
          @click="$emit('backList')"
          class="mr-1"
          style="color: #434343; font-size: 1.2rem; cursor: pointer"
        >
          Orders /
          <span
            class="ml-1"
            style="color: #434343; font-size: 1.2rem; font-weight: 600"
          >
            {{ selectedOrder.id }}
          </span>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="vx-row BgWhiteBS p-4 items-center">
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Order No: <span>421</span>
        </div>
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Created : <span> 2019-01-01</span>
        </div>

        <div class="vx-col flex md:w-1/3 sm:w-1/2 w-full">
          <div class="mr-4">Order Status:</div>
          <vs-chip :color="GetOrderStatusColor('R')">Ready</vs-chip>
        </div>

        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Total Gold (14K) : <span>12</span>
        </div>

        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Total Resin/Credit: <span>32</span>
        </div>

        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Machine Type : <span>Sega 90</span>
        </div>
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          Customer : <span>Company 46</span>
        </div>
      </div>
    </div>

    <div class="w-full m-2" style="font-size: 1.2rem; font-weight: 600">
      Products
    </div>

    <div class="vx-row">
      <div
        class="vx-col md:w-1/4 sm:w-1/2 w-full"
        v-for="productGroup in productGroups"
        :key="productGroup.id"
      >
        <div class="vx-row p-2 mt-2 ml-1 mr-1 BgWhite">
          <div class="vx-col w-full">
            <swiper
              :options="swiperOption"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :key="$vs.rtl"
            >
              <swiper-slide
                v-for="image in productGroup.images"
                :key="image.id"
              >
                <div
                  class="flex items-center"
                  :style="'height:' + maxImageHeight + 'px'"
                >
                  <img
                    ref="image"
                    @load="SetMaxHeight"
                    class="popupImage"
                    :src="image.image_thumbnail"
                    alt="banner"
                  />
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
          <div class="vx-col pr-2 pl-2 w-full">
            <div
              style="font-size: 1.1rem; color: #000; cursor: pointer"
              @click="OpenProductGroup(productGroup.id)"
            >
              {{ GetTitleFromProductGroup(productGroup.id) }}
            </div>

            <div
              class="flex justify-between pr-2"
              style="cursor: pointer; font-weight: 500; font-size: 0.9rem"
              @click="OpenProductGroup(productGroup.id)"
            >
              <div>
                Resin/Credit: {{ GetTotalProductGroupWeight(productGroup.id) }}
              </div>
              <div class="">
                Gold ({{ selectedGoldWeightTypeStore.weightType }}) :
                {{
                  (
                    GetTotalProductGroupVolume(productGroup.id) *
                    selectedGoldWeightTypeStore.value
                  ).toFixed(2)
                }}
              </div>
            </div>

            <vs-icon
              class="flex justify-center w-full"
              v-if="productGroup.id == expandedDetail"
              color="#D1D1D1"
              icon="expand_less"
              style="cursor: pointer"
              size="large"
              @click="expandedDetail = null"
            >
            </vs-icon>

            <vs-icon
              class="flex justify-center mt-6 w-full"
              v-else
              color="#D1D1D1"
              icon="expand_more"
              style="cursor: pointer"
              size="large"
            >
            </vs-icon>
          </div>
        </div>
        <div
          class="detailExpand pr-4 pl-4 pb-4 mr-1 ml-1"
          v-if="expandedDetail == productGroup.id"
        >
          <vs-divider class="m-0 p-0 mb-2"></vs-divider>

          <div
            class="vx-row justify-between pr-4 pl-4 mt-1"
            v-for="item in GetItemsWithProductGroupId(productGroup.id)"
            :key="item.id"
          >
            <div>
              {{ item.product_title }}
            </div>
            <div>
              {{ item.quantity }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  props: ["selectedOrder"],
  data() {
    return {
      expandedDetail: null,
      order: {
        id: 154,
        order_status: "R",
        order_status_display: "Hazır",
        total_volume: 3138,
        total_weight: 4.08,
        machine_type: "Sega 90",
        plate_width: 90,
        plate_height: 50,
        created: "2022-04-05T13:35:02.967863+03:00",
        items: [
          {
            id: 1201,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-B-1",
            product_weight: 0.02,
            product_volume: 18,
            quantity: 1,
          },
          {
            id: 1200,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-B-2",
            product_weight: 0.03,
            product_volume: 22,
            quantity: 1,
          },
          {
            id: 1199,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-B-3",
            product_weight: 0.11,
            product_volume: 85,
            quantity: 1,
          },
          {
            id: 1198,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-B-4",
            product_weight: 0.03,
            product_volume: 23,
            quantity: 1,
          },
          {
            id: 1197,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-B-5",
            product_weight: 0.02,
            product_volume: 19,
            quantity: 1,
          },
          {
            id: 1196,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-E-1",
            product_weight: 0.11,
            product_volume: 81,
            quantity: 1,
          },
          {
            id: 1195,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-E-2",
            product_weight: 0.11,
            product_volume: 81,
            quantity: 1,
          },
          {
            id: 1194,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-N-1",
            product_weight: 0.04,
            product_volume: 33,
            quantity: 1,
          },
          {
            id: 1193,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-N-2",
            product_weight: 0.22,
            product_volume: 171,
            quantity: 1,
          },
          {
            id: 1192,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-N-3",
            product_weight: 0.38,
            product_volume: 291,
            quantity: 1,
          },
          {
            id: 1191,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-N-4",
            product_weight: 0.3,
            product_volume: 233,
            quantity: 1,
          },
          {
            id: 1190,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-N-6",
            product_weight: 0.22,
            product_volume: 171,
            quantity: 1,
          },
          {
            id: 1189,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-N-7",
            product_weight: 0.04,
            product_volume: 33,
            quantity: 1,
          },
          {
            id: 1188,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-N-8",
            product_weight: 0.36,
            product_volume: 277,
            quantity: 1,
          },
          {
            id: 1187,
            product_group: 630,
            product_group_title: "FSO02",
            product_title: "FSO02-R-58",
            product_weight: 0.26,
            product_volume: 203,
            quantity: 1,
          },
          {
            id: 1214,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-B-1",
            product_weight: 0.04,
            product_volume: 29,
            quantity: 1,
          },
          {
            id: 1213,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-B-2",
            product_weight: 0.15,
            product_volume: 113,
            quantity: 1,
          },
          {
            id: 1212,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-B-3",
            product_weight: 0.04,
            product_volume: 29,
            quantity: 1,
          },
          {
            id: 1211,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-E-1",
            product_weight: 0.08,
            product_volume: 59,
            quantity: 1,
          },
          {
            id: 1210,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-E-2",
            product_weight: 0.08,
            product_volume: 59,
            quantity: 1,
          },
          {
            id: 1209,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-N-1",
            product_weight: 0.03,
            product_volume: 26,
            quantity: 1,
          },
          {
            id: 1208,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-N-2",
            product_weight: 0.11,
            product_volume: 87,
            quantity: 1,
          },
          {
            id: 1207,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-N-3",
            product_weight: 0.21,
            product_volume: 163,
            quantity: 1,
          },
          {
            id: 1206,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-N-4",
            product_weight: 0.5,
            product_volume: 387,
            quantity: 1,
          },
          {
            id: 1205,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-N-5",
            product_weight: 0.21,
            product_volume: 163,
            quantity: 1,
          },
          {
            id: 1204,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-N-6",
            product_weight: 0.11,
            product_volume: 87,
            quantity: 1,
          },
          {
            id: 1203,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-N-7",
            product_weight: 0.03,
            product_volume: 26,
            quantity: 1,
          },
          {
            id: 1202,
            product_group: 631,
            product_group_title: "FSO03",
            product_title: "FSO03-R-58",
            product_weight: 0.22,
            product_volume: 169,
            quantity: 1,
          },
        ],
        plates: [
          { id: 59, plate: 14888, quantity: 1 },
          { id: 60, plate: 14889, quantity: 1 },
        ],
        images: [
          {
            id: 1759,
            product_group: 630,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02.jpg",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02/72aaa224fbc734db1fc34cfd65fd1dd8.jpg",
            order: 10,
          },
          {
            id: 1763,
            product_group: 631,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03.jpg",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03/2439dcc1db750ad27b80620c65c379da.jpg",
            order: 10,
          },
          {
            id: 1761,
            product_group: 630,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02-1.JPG",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02-1/f208fdd89e5dbc52e75c5d8d030ac858.JPG",
            order: 11,
          },
          {
            id: 1765,
            product_group: 631,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03-1.JPG",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03-1/c0727a8b486276f2d40646561ab47f82.JPG",
            order: 11,
          },
          {
            id: 1764,
            product_group: 631,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03-2.JPG",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03-2/7c2052832640a476f59e5ad3ecf557ef.JPG",
            order: 12,
          },
          {
            id: 1760,
            product_group: 630,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02-2.JPG",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02-2/6a86ce489e7ae0ee6868fb533fb768d8.JPG",
            order: 12,
          },
          {
            id: 1762,
            product_group: 630,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02-3.jpg",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02-3/056fe8268ad688150cb2fb21ec2206b8.jpg",
            order: 13,
          },
          {
            id: 1766,
            product_group: 631,
            image:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03-3.jpg",
            image_thumbnail:
              "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03-3/a2097e518e2c1d6b151560eb2b8c245a.jpg",
            order: 13,
          },
        ],
      },
      maxImageHeight: 0,
      swiperOption: {
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      productGroups: [
        {
          id: 630,
          images: [
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02.jpg",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02/72aaa224fbc734db1fc34cfd65fd1dd8.jpg",
              order: 10,
            },
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02-1.JPG",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02-1/f208fdd89e5dbc52e75c5d8d030ac858.JPG",
              order: 11,
            },
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02-2.JPG",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02-2/6a86ce489e7ae0ee6868fb533fb768d8.JPG",
              order: 12,
            },
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO02-3.jpg",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO02-3/056fe8268ad688150cb2fb21ec2206b8.jpg",
              order: 13,
            },
          ],
        },
        {
          id: 631,
          images: [
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03.jpg",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03/2439dcc1db750ad27b80620c65c379da.jpg",
              order: 10,
            },
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03-1.JPG",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03-1/c0727a8b486276f2d40646561ab47f82.JPG",
              order: 11,
            },
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03-2.JPG",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03-2/7c2052832640a476f59e5ad3ecf557ef.JPG",
              order: 12,
            },
            {
              image:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/FSO03-3.jpg",
              image_thumbnail:
                "https://novahub-dev.s3.eu-central-1.amazonaws.com/nokta-market/product-groups/CACHE/images/FSO03-3/a2097e518e2c1d6b151560eb2b8c245a.jpg",
              order: 13,
            },
          ],
        },
      ],
    };
  },
  methods: {
    GetTotalProductGroupVolume(id) {
      let totalVolume = 0;
      this.order.items.forEach((item) => {
        if (id == item.product_group) {
          totalVolume += item.product_volume * item.quantity;
        }
      });
      return totalVolume;
    },
    GetTotalProductGroupWeight(id) {
      let totalWeight = 0;
      this.order.items.forEach((item) => {
        if (id == item.product_group) {
          totalWeight += item.product_weight * item.quantity;
        }
      });
      return totalWeight.toFixed(2);
    },
    SetMaxHeight(event) {
      let div = event.target;
      if (div.clientHeight > this.maxImageHeight) {
        this.maxImageHeight = div.clientHeight;
      }
    },
    GetTitleFromProductGroup(id) {
      for (let index = 0; index < this.order.items.length; index++) {
        const element = this.order.items[index];
        if (element.product_group == id) {
          return element.product_group_title;
        }
      }
    },
    GetOrderStatusColor(status) {
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "C") return "danger";
      if (status == "W") return "#389CA3";
      return "primary";
    },
  },
  computed: {
    selectedGoldWeightTypeStore() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
  },
  components: {
    swiper,
    swiperSlide,
  },
};
</script>

<style>
.detailExpand {
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 0.8rem;
}
.BgWhite {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: 600;
}
.popupImage {
  width: 100%;
  height: auto;
}
.vx-row {
  padding: 0px;
  margin: 0px;
}
.vx-col {
  padding: 0px;
  margin: 0px;
}
</style>

<style scoped>
span {
  font-weight: 600;
}
</style>
