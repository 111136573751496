<template>
  <div>
    <Transition name="fade" mode="out-in">
      <component
        :is="activeComponent"
        @backList="BackList"
        @OpenOrderDetail="OpenOrderDetail"
        :selectedOrder="selectedOrder"
      ></component>
    </Transition>
  </div>
</template>

<script>
import orderList from "./components/OrderLists.vue";
import orderDetail from "./components/OrderDetail.vue";

export default {
  data() {
    return {
      selectedOrder: null,
      activeComponent: "orderList",
    };
  },
  methods: {
    OpenOrderDetail(order) {
      this.activeComponent = "orderDetail";
      this.selectedOrder = order;
    },
    BackList() {
      this.activeComponent = "orderList";
      this.selectedOrder = null;
    },
  },
  components: {
    orderList,
    orderDetail,
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
